import React, { useState } from "react";
import YTPlayer from "./YTPlayer";
import Sidebar from "./Sidebar";
import Content from "./Content";
import EventSchedule from "./EventSchedule";
import ExhibitionList from "./ExhibitionList";
import "./Main.sass";
import { useSidebarState } from "../store/SidebarProvider";
import { useStreamInfo } from "../store/StreamInfoContext";

function Main() {
  const sidebarState = useSidebarState();
  const streamInfo = useStreamInfo();
  const [time, setTime] = useState();

  return (
    <main className="main">
      <Sidebar open={sidebarState.leftSidebarOpened} width="350px">
        <EventSchedule
          itemBackgroundColor={streamInfo.page_item_backgroundColor}
          sectionFontColor={streamInfo.shedule_section_title_font_color}
          color={streamInfo.page_font_color}
          backgroundColor={streamInfo.page_backgroundColor}
          onSelect={(item) =>
            (item.time || item.time === 0) && setTime(item.time)
          }
        />
      </Sidebar>
      <Content>
        <YTPlayer
          url={streamInfo.yt_url}
          fromStart={streamInfo.from_start}
          time={time}
        />
      </Content>
      <Sidebar open={sidebarState.rightSidebarOpened} width="350px">
        <ExhibitionList
          itemBackgroundColor={streamInfo.page_item_backgroundColor}
          color={streamInfo.page_font_color}
          backgroundColor={streamInfo.page_backgroundColor}
        />
      </Sidebar>
    </main>
  );
}

export default Main;
