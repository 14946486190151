import React, { useEffect, useRef, useState } from "react";
import "./YTPlayer.sass";
import YTPlayer from "youtube-player";

function YtPlayer({ url, fromStart, time }) {
  const [player, setPlayer] = useState();
  const playerRef = useRef();

  useEffect(() => {
    setPlayer(YTPlayer(playerRef.current));
  }, []);

  useEffect(() => {
    if (player && fromStart) {
      player.seekTo(time || 0);
    }
  }, [fromStart, player, time]);

  useEffect(() => {
    if (time || time === 0) {
      player.seekTo(time);
    }
  }, [time, player]);

  return (
    <div className="yt-player">
      <iframe
        ref={playerRef}
        title="yt-player"
        style={{ width: "100%", height: "100%" }}
        src={url ? url + "?controls=1&amp;autoplay=1&amp;enablejsapi=1" : ""}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

export default YtPlayer;
